module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Artz Auto's in Velp","short_name":"ArtzAutos","start_url":"/?pwa=true","background_color":"#0a0a0a","theme_color":"#d22309","display":"minimal-ui","icon":"src/favicon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ca76d63e6793c4de40d0ffd5354cf9e8"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://d752ae4ce6a44417adcfc2fa13249b8f@o409936.ingest.sentry.io/5457469","tracesSampleRate":1,"release":"gatsby@2025-04-10T13:47:07.697Z","environment":"production","enabled":true,"debug":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"sm":"(min-width: 36rem)","md":"(min-width: 48rem)","lg":"(min-width: 62rem)","xl":"(min-width: 75rem)"}},
    },{
      plugin: require('../../../packages/gatsby-theme-menus/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../packages/gatsby-theme-apolloclient/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/acm/gatsby-browser.js'),
      options: {"plugins":[],"core":{"avif":false,"sourceStrapi":{"themes":{"vehicles":true,"vehicleLease":true,"employees":true,"rating":true,"pagebuilder":true}}}},
    },{
      plugin: require('../../../packages/gatsby-theme-pagebuilder/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-9RRZGEBD4P","UA-18510282-10"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
